import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import "./styles.css";
import Header from "./components/Header";

const Contact = () => {
  return (
    <>
      <NavBar />
      <Header />
      {/* TODO: Add footer */}
      {/* <Footer /> */}
    </>
  );
};

export default Contact;
