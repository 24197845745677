import Grid from "@mui/material/Unstable_Grid2";
import "../styles.css";
import {
  SectionHeading,
  Subheading,
  InfoWrapper,
  InformationMessage,
  Med,
} from "../styles";
import { COLORS } from "../../../constants";

const About = () => {
  return (
    <Grid container sx={{ background: COLORS.BACKGROUND_NEUTRAL }}>
      <Grid xs={1} md={2}></Grid>
      <Grid xs={10} md={8}>
        <InfoWrapper>
          <SectionHeading>About</SectionHeading>
          <InformationMessage>
            Big Sand Volleyball Club will be running two club teams this 2025 season:
            <br />
            13U - Born September 1, 2011 to December 31, 2012
            <br />
            14U - Born September 1, 2010 to December 31, 2011
          </InformationMessage>  
          <Subheading>TRYOUTS</Subheading>
          <InformationMessage>
            Tryouts for both 13U and 14U teams will take place together.
            Tryouts will take place on <Med>Saturday, December 7th</Med> at Sturgeon Heights Community Center
            from <Med>7:30-9:30 AM</Med> (yes, early morning!)
          </InformationMessage>
          <Subheading>COST</Subheading>
          <InformationMessage>Tryout fee is $20</InformationMessage>
          <Subheading>HOW</Subheading>
          <InformationMessage>
            Payment can be made by e-transfer, cash or cheque after sign-up
          </InformationMessage>
        </InfoWrapper>
      </Grid>
    </Grid>
  );
};

export default About;
